<template>
  <div>
    <!-- 凭证列表 -->
    <div class="voucherWrap">
      <div
        class="item"
        v-for="item in paginationVoucherList"
        :key="item.EvidenceID"
      >
        <!-- <i class="delete el-icon-delete" title="删除凭证" @click="deleteVoucher(item.EvidenceImgUrl)"></i> -->
        <el-image
          style="width: 200px; height: 100px; margin-bottom: 10px;"
          :src="item.EvidenceImgUrl"
          :preview-src-list="[item.EvidenceImgUrl]"
        >
          <div
            slot="error"
            class="image-slot"
            style="display: flex; justify-content: center; margin-top: 30px;"
          >
            <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
          </div>
        </el-image>
        <!-- <el-tooltip :content="item.FileName">
                    <p style="margin-bottom: 8px;" @click="downloadVoucher(item.EvidenceImgUrl)">{{ item.FileName }}</p>
                </el-tooltip> -->
      </div>
      <div v-if="currentVoucherList.length == 0" class="placeholder">
        暂无凭证
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination
      background
      style="margin-top: 16px;"
      @current-change="setPagination"
      :current-page.sync="pagination.page"
      :page-size="pagination.pagesize"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <div class="voucherBtn">
      <el-button style="margin-bottom: 16px;" @click="uploadVoucher(false)"
        >关闭</el-button
      >
      <el-button
        :disabled="currentVoucherList.length < 1"
        style="margin-bottom: 16px;"
        @click="downEvidence"
        type="primary"
        >下载</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  getEvidence,
  downEvidenceStream,
} from "@/api/waybill/pendingManage/offlineVoucher";

export default {
  data() {
    return {
      // flag: {
      //     uploadvouchering: false //上传凭证中
      // },
      pagination: {
        page: 1,
        pagesize: 10,
        total: 1,
      },
      currentVoucherList: [], //当前操作的凭证
      paginationVoucherList: [], // 分页展示的凭证
    };
  },
  computed: {
    ...mapGetters(["getInvoiceTaskID"]),
  },
  methods: {
    // 设置分页
    setPagination(page, total) {
      setTimeout(() => {
        this.pagination.page = page;
        if (total || total == 0) this.pagination.total = total;
      }, 100);
      this.paginationVoucherList = this.currentVoucherList.slice(
        (page - 1) * 10,
        page * 10
      );
    },

    // 获取更新凭证列表
    getUploadFile() {
      this.currentVoucherList = [];
      getEvidence(
        {
          pageSize: 1000,
          pageIndex: 1,
        },
        this.getInvoiceTaskID
      ).then((res) => {
        let { DataList, PageIndex, TotalCount } = res.data;
        this.currentVoucherList = DataList;
        this.setPagination(PageIndex, TotalCount);
      });
    },

    // 点击凭证， 关闭父组件中的dialog
    uploadVoucher(flag) {
      this.$emit("uploadVoucher", flag);
    },

    // 下载凭证
    downEvidence() {
      const loading = this.$loading({
        lock: true,
        text: "下载凭证中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      downEvidenceStream(this.getInvoiceTaskID, {
        responseType: "blob",
      }).catch((err) => {
        let { data } = err;
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function(e) {
            const { msg, title } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg || title}`,
              type: "error",
            });
            loading.close();
          };
          return;
        }

        const blob = new Blob([data]);
        let fileName = err.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.voucherWrap {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  //   border: 1px solid red;

  .item {
    width: 19%;
    margin: 16px 0;
    position: relative;

    .delete {
      cursor: pointer;
      font-size: 20px;
      position: absolute;
      bottom: 30px;
      right: 30px;
      z-index: 9999;
    }

    p {
      width: 200px;
      color: blue;
      cursor: pointer;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .placeholder {
    margin: 0 auto 2%;
    font-size: 25px;
    font-weight: bolder;
    color: #ccc;
  }
}

.voucherBtn {
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
</style>
